import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import parse from 'html-react-parser'
import Skeleton from 'react-loading-skeleton'

import {
    callCalender,
    callLieu,
    callTarifs,
    saveCalender,
    saveLieu,
    saveTarifs,
} from '../CallApi'

import file from '../assets/telechargements/heraldique_inscription_2020_2021.pdf'
import { modules } from '../modules'

const Inscription = ({ connected }) => {
    const [calender, setCalender] = useState(``)
    const [tarifs, setTarifs] = useState(``)
    const [lieu, setLieu] = useState(``)
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState('LOADED')

    useEffect(() => {
        callCalender(setCalender, setLoading)
        callLieu(setLieu, setLoading)
        callTarifs(setTarifs, setLoading)
    }, [])

    useEffect(() => {
        if (connected && updating === 'ENDED_CALENDER') {
            saveCalender(calender)
        } else if (connected && updating === 'ENDED_TARIFS') {
            saveTarifs(tarifs)
        } else if (connected && updating === 'ENDED_LIEU') {
            saveLieu(lieu)
        }
    }, [calender, tarifs, lieu, connected, updating])

    const handleCalenderChange = (text) => {
        setCalender(text)
        setUpdating('CHANGING')
    }

    const handleTarifsChange = (text) => {
        setTarifs(text)
        setUpdating('CHANGING')
    }

    const handleLieuChange = (text) => {
        setLieu(text)
        setUpdating('CHANGING')
    }

    return (
        <>
            <div id="inscriptions" className="row justify-content-center mt-5">
                <div className="col-lg-5 col-sm-8 col-xs-12">
                    <div className="row planBox premium text-center">
                        <div className="col-md-12 section1">
                            <p>Calendrier</p>
                        </div>
                        <div className="col-md-12 section2">
                            {loading ? (
                                <div className="container">
                                    <Skeleton count={2} />
                                </div>
                            ) : connected ? (
                                <>
                                    <ReactQuill
                                        theme="snow"
                                        value={calender}
                                        onChange={handleCalenderChange}
                                        onBlur={() =>
                                            setUpdating('ENDED_CALENDER')
                                        }
                                        modules={modules}
                                    />
                                </>
                            ) : (
                                <>{parse(calender)}</>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-8 col-xs-12">
                    <div className="row planBox text-center">
                        <div className="col-md-12 section1">
                            <p>Tarifs</p>
                        </div>
                        <div className="col-md-12 section2">
                            {loading ? (
                                <div className="container">
                                    <Skeleton count={2} />
                                </div>
                            ) : connected ? (
                                <>
                                    <ReactQuill
                                        theme="snow"
                                        value={tarifs}
                                        onChange={handleTarifsChange}
                                        onBlur={() =>
                                            setUpdating('ENDED_TARIFS')
                                        }
                                        modules={modules}
                                    />
                                </>
                            ) : (
                                <>{parse(tarifs)}</>
                            )}
                        </div>
                    </div>
                    <div className="row planBox text-center">
                        <div className="col-md-12 section1">
                            <p>Lieu</p>
                        </div>
                        <div className="col-md-12 section2">
                            {loading ? (
                                <div className="container">
                                    <Skeleton count={2} />
                                </div>
                            ) : connected ? (
                                <>
                                    <ReactQuill
                                        theme="snow"
                                        value={lieu}
                                        onChange={handleLieuChange}
                                        onBlur={() => setUpdating('ENDED_LIEU')}
                                        modules={modules}
                                    />
                                </>
                            ) : (
                                <>{parse(lieu)}</>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center flex-column mt-5">
                <p className="col-md-9 font-weight-bold">
                    Ci-dessous, le formulaire d’inscription à renvoyer par
                    courrier à l’Association pour la promotion du patrimoine
                    héraldique européen, 16 rue Cauchy 75015 Paris, accompagné
                    du règlement par chèque (à l’ordre de l’Association).
                </p>

                <a
                    className="btn btn-danger btn-md mt-3"
                    href={file}
                    target="_blank"
                    rel="noreferrer"
                >
                    Télécharger le formulaire d'inscription
                </a>
            </div>
        </>
    )
}
export default Inscription
