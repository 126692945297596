import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import trente_chateau_71 from '../assets/images/Trente_chateau_71.jpg'
import parse from 'html-react-parser'
import { modules } from '../modules'
import { callApropos, saveApropos } from '../CallApi'
import Skeleton from 'react-loading-skeleton'

const APropos = ({ connected }) => {
    const [value, setValue] = useState(``)
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState('LOADED')

    useEffect(() => {
        callApropos(setValue, setLoading)
    }, [])

    useEffect(() => {
        if (connected && updating === 'ENDED') {
            const timeOutId = setTimeout(() => saveApropos(value), 500)
            return () => clearTimeout(timeOutId)
        }
    }, [value, connected, updating])

    const handleChange = (text) => {
        setValue(text)
        setUpdating('CHANGING')
    }

    return (
        <section id="a_propos" className="row">
            <div className="col-lg-3 col-sm-4 col-8 offset-2 offset-sm-0">
                <img
                    src={trente_chateau_71}
                    className="rounded img-shadow img-fluid"
                    alt="wrapkit"
                />
            </div>
            <div className="col-lg-6 col-sm-7 mt-lg-4 ml-lg-5 col-12">
                <div className="text-box">
                    {loading ? (
                        <div>
                            <Skeleton count={7} />
                        </div>
                    ) : connected ? (
                        <>
                            <ReactQuill
                                theme="snow"
                                value={value}
                                onChange={handleChange}
                                onBlur={() => setUpdating('ENDED')}
                                modules={modules}
                            />
                        </>
                    ) : (
                        <>{parse(value)}</>
                    )}
                </div>
            </div>
        </section>
    )
}
export default APropos
