import React from 'react'

const Nav = () => {
    return (
        <nav
            id="topbar"
            className="navbar navbar-expand justify-content-around"
            style={{ background: '#f4ecd5', color: '#000' }}
        >
            <div className="container">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <a
                                className="nav-link"
                                href="https://heraldica.palisep.fr"
                            >
                                <i
                                    className="fa fa-lg fa-home bg-black"
                                    style={{ color: 'black' }}
                                ></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#a_propos">
                                A Propos
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#cours">
                                Cours
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#inscriptions">
                                Inscriptions
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#contact">
                                Contact
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="http://palisep.fr">
                                Palisep
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default Nav
