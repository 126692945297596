import React, { useEffect, useState } from 'react'
import orga_img from '../assets/images/20190413_173220.jpg'
import parse from 'html-react-parser'
import { modules } from '../modules'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {
    callIntro,
    saveIntro,
    callFirst,
    saveFirst,
    callSecond,
    saveSecond,
    callSpecify,
    saveSpecify,
} from '../CallApi'
import Skeleton from 'react-loading-skeleton'

const Cours = ({ connected }) => {
    const [intro, setIntro] = useState(``)
    const [first, setFirst] = useState(``)
    const [second, setSecond] = useState(``)
    const [specify, setSpecify] = useState(``)
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState('LOADED')

    useEffect(() => {
        callIntro(setIntro, setLoading)
        callFirst(setFirst, setLoading)
        callSecond(setSecond, setLoading)
        callSpecify(setSpecify, setLoading)
    }, [])

    useEffect(() => {
        if (connected && updating === 'ENDED_SPECIFY') {
            const timeOutId = setTimeout(() => {
                saveSpecify(specify)
            }, 500)
            return () => clearTimeout(timeOutId)
        }
    }, [specify, connected, updating])

    useEffect(() => {
        if (connected && updating === 'ENDED_SECOND') {
            const timeOutId = setTimeout(() => {
                saveSecond(second)
            }, 500)
            return () => clearTimeout(timeOutId)
        }
    }, [second, connected, updating])

    useEffect(() => {
        if (connected && updating === 'ENDED_FIRST') {
            const timeOutId = setTimeout(() => {
                saveFirst(first)
            }, 500)
            return () => clearTimeout(timeOutId)
        }
    }, [first, connected, updating])

    useEffect(() => {
        if (connected && updating === 'ENDED_INTRO') {
            const timeOutId = setTimeout(() => {
                saveIntro(intro)
            }, 500)
            return () => clearTimeout(timeOutId)
        }
    }, [intro, connected, updating])

    const handleIntro = (text) => {
        setIntro(text)
        setUpdating('CHANGING')
    }

    const handleFirst = (text) => {
        setFirst(text)
        setUpdating('CHANGING')
    }
    const handleSecond = (text) => {
        setSecond(text)
        setUpdating('CHANGING')
    }

    const handleSpecify = (text) => {
        setSpecify(text)
        setUpdating('CHANGING')
    }
    return (
        <section id="cours">
            <div className="row justify-content-center">
                <div className="col-md-7 text-center">
                    <h2 className="title">Organisation des cours</h2>
                    <hr className="primary mb-5" />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-sm-6">
                    {loading ? (
                        <div>
                            <Skeleton count={5} />
                        </div>
                    ) : connected ? (
                        <>
                            <ReactQuill
                                theme="snow"
                                value={intro}
                                onChange={handleIntro}
                                onBlur={() => setUpdating('ENDED_INTRO')}
                                modules={modules}
                            />
                        </>
                    ) : (
                        <>{parse(intro)}</>
                    )}
                </div>
                <div className="col-lg-3 col-sm-4 offset-lg-2 ml-lg-5 col-8 offset-2">
                    <img className="img-fluid rounded" src={orga_img} alt="" />
                </div>
            </div>
            <div className="row mt-4">
                <h5 className="mb-3">
                    Ce cours est organisé sur un cycle de deux années :
                </h5>
            </div>
            <div className="row">
                <div className="col-md-5 border-right">
                    <div
                        className="card border-0"
                        data-aos="zoom-out-right"
                        data-aos-duration="1200"
                    >
                        <div className="card-body">
                            {loading ? (
                                <div>
                                    <Skeleton count={2} />
                                </div>
                            ) : connected ? (
                                <>
                                    <ReactQuill
                                        theme="snow"
                                        value={first}
                                        onChange={handleFirst}
                                        onBlur={() =>
                                            setUpdating('ENDED_FIRST')
                                        }
                                        modules={modules}
                                    />
                                </>
                            ) : (
                                <>{parse(first)}</>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div
                        className="card border-0"
                        data-aos="zoom-out-left"
                        data-aos-duration="1200"
                    >
                        <div className="card-body">
                            {loading ? (
                                <div className="container">
                                    <Skeleton count={2} />
                                </div>
                            ) : connected ? (
                                <>
                                    <ReactQuill
                                        theme="snow"
                                        value={second}
                                        onChange={handleSecond}
                                        onBlur={() =>
                                            setUpdating('ENDED_SECOND')
                                        }
                                        modules={modules}
                                    />
                                </>
                            ) : (
                                <>{parse(second)}</>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row flex-column">
                {loading ? (
                    <div>
                        <Skeleton count={1} />
                    </div>
                ) : connected ? (
                    <>
                        <ReactQuill
                            theme="snow"
                            value={specify}
                            onChange={handleSpecify}
                            onBlur={() => setUpdating('ENDED_SPECIFY')}
                            modules={modules}
                        />
                    </>
                ) : (
                    <>{parse(specify)}</>
                )}
            </div>
        </section>
    )
}
export default Cours
